import {default as React} from "react"
import {CgArrowLongRight} from "react-icons/cg"

const CareersIntro = ({ intro: { title = '', subtitle = '', description = '', button: { text = '', url = ''}}}) => {
  return (
    <div
      className="flex flex-col">
      <h6
        className="text-secondary mb-4">{ subtitle }</h6>
      <h2
        className="text-5xl text-primary mb-12">{ title }</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: description
        }} />
      <div>
        <a
          className="btn btn-primary"
          rel="noreferrer"
          target="_blank"
          href={url}>
          <span className="mr-4">{text}</span>
          <CgArrowLongRight />
        </a>
      </div>
    </div>
  )
}

export default CareersIntro