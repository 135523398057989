import { default as React } from "react"
import { graphql } from "gatsby"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import Callout from "~/components/callout"
import Intro from "~/components/careers/intro"
import Benefits from "~/components/careers/benefits"

const CareersPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo title={page.title} />
      <section
        style={{
          backgroundImage: `url('${page?.featuredImage?.node?.localFile?.childImageSharp?.fluid?.base64}')`,
          borderBottomRightRadius: '50% 10%'
        }}
        className="relative pt-44 bg-primary bg-cover bg-center overflow-hidden text-white">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full flex flex-col md:w-1/3 px-8">
              <h1
                className="text-5xl md:text-7xl text-white mb-12 md:mb-20">{page.title}
              </h1>
              <small className="text-secondary mb-10">When it Matters</small>
            </div>
            <div className="flex justify-end items-center w-full md:w-2/3 px-8">

            </div>
          </div>
        </div>
      </section>
      <section className="pt-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full md:w-1/2 px-8 mb-12 md:mb-0">
              <Intro
                intro={page.careers?.intro} />
            </div>
            <div className="w-full md:w-1/3 md:ml-16 px-8">
              <Benefits
                benefits={page.careers?.benefits} />
            </div>
          </div>
        </div>
      </section>
      {/** Callout Section */}
      { page.callout && (
        <Callout
          callout={page.callout} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query CareersQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId }){
      title
      content
      featuredImage {
        node {
          localFile{
            childImageSharp{
              fluid(toFormatBase64: WEBP, base64Width: 2000){
                base64
              }
            }
          } 
        }
      } 
      careers{
        intro: careersIntro{
          title
          subtitle
          description
          button{
            text
            url
          }
        }
        benefits{
          benefit
        }
      }
      callout {
        title
        subtitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          position
        }
        button {
          text
          url
        }
      }
    }
  }
`

export default CareersPage